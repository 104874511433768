import React from "react";
import PropTypes from "prop-types";

/**
 * CoverImage
 */
const CoverImage = props => {
  return (
    <img
      className="w-full h-auto max-h-600 object-cover px-60"
      src={props.imageUrl}
      alt={props.alt}
    />
  );
};

CoverImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};
CoverImage.defaultProps = {};

export default CoverImage;
