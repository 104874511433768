import React, { createRef } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

import theme from "../../constants/theme";

import { Button, LocaleLink } from "../core";

/**
 * Cookie
 */
const Cookie = props => {
  const cookieRef = createRef();

  let COOKIE_NAME = "gatsby-gdpr-google-analytics";

  return Cookies.get(COOKIE_NAME) === undefined ? message() : null;

  function setCookies(value = true) {
    Cookies.set(COOKIE_NAME, value);
    cookieRef.current.style.display = "none";
  }

  function message() {
    return (
      <div
        ref={cookieRef}
        css={{ zIndex: 5000 }}
        className="fixed left-20 bottom-20 right-20 md:right-auto bg-white rounded-md shadow-md px-12 py-12">
        <div className="flex flex-col md:flex-row justify-between items-end md:items-center h-full">
          <div className="min-w-240 md:max-w-300">
            <p className="text-sm2 text-text1 leading-normal">
              {props.description} {link()}.
            </p>
          </div>

          <div className="w-none md:w-10 h-16 md:h-none bg-transparent" />

          <div className="flex">
            <Button
              onClick={() => setCookies(false)}
              mode="button"
              label={props.subButtonTitle}
              labelColor={theme.colors.text1}
              frameColor={theme.colors.altBg1_dimmed}
              frameColorHover={theme.colors.altBg1_dimmed_hover}
              size={`sm`}
              isStretch={false}
            />

            <div className="w-4 bg-transparent" />

            <Button
              onClick={() => setCookies()}
              mode="button"
              label={props.mainButtonTitle}
              frameColor={theme.colors.accent1}
              frameColorHover={theme.colors.accent1_hover}
              size={`sm`}
              isStretch={false}
            />
          </div>
        </div>
      </div>
    );
  }

  function link() {
    return (
      <LocaleLink to={props.detailPageRoute} className="text-accent1 hover:text-accent1_hover">
        {props.detailPageTitle}
      </LocaleLink>
    );
  }
};

Cookie.propTypes = {
  description: PropTypes.string,
  mainButtonTitle: PropTypes.string,
  subButtonTitle: PropTypes.string,
  detailPageTitle: PropTypes.string,
  detailPageRoute: PropTypes.string,
};
Cookie.defaultProps = {
  description:
    "We use cookies to give you the best possible experience on our website. By continuing to browse this site, you give consent for cookies to be used. For more details, please read our",
  mainButtonTitle: "Allow",
  subButtonTitle: "No thanks",
  detailPageTitle: "Privacy policy",
  detailPageRoute: "/privacy",
};

export default Cookie;
