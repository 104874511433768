import React from "react";
import PropTypes from "prop-types";

const GlyphCaretDown = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16">
      <path
        stroke={color}
        strokeWidth={2}
        d="M3 6l4.95 4.95L12.899 6"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

GlyphCaretDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
GlyphCaretDown.defaultProps = { color: "black", size: 16 };

export default GlyphCaretDown;
