export const mockSiteMetaData = {
  company: {
    companyName: "Company name",
    startYear: 2020,
  },
  contact: {
    email: "hello@chipchip.nl",
  },
};

export const mockContactFormConfirmation = {
  title: "Thank you",
  description: "Thank you for your message.",
  buttonTitle: "Send another one",
};
