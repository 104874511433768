import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Color from "color";

/**
 * Button
 */
const Button = props => {
  const {
    mode,
    to,
    href,
    onClick,
    label,
    icon,
    iconPosition,
    size,
    roundness,
    labelColor,
    frameColor,
    isStretch,
  } = props;

  const _buttonSize = {
    sm: 40,
    md: 48,
    lg: 56,
    xl: 64,
  };
  const _buttonSidePadding = {
    sm: 10,
    md: 12,
    lg: 20,
    xl: 20,
  };
  const _buttonShape = {
    none: 0,
    classic: 8,
    round: 9999,
  };
  const _labelSize = {
    sm: 16,
    md: 18,
    lg: 18,
    xl: 20,
  };
  const _buttonWidth = isStretch ? "100%" : "auto";

  const _icon = () => {
    const _iconStyle =
      iconPosition === "right"
        ? "ml-6 order-last"
        : iconPosition === "left"
        ? "mr-6 order-first"
        : "ml-6 order-last";

    return icon ? <div className={_iconStyle}>{icon}</div> : null;
  };

  const GatsbyLink = props => {
    const { children, to, size, frameColor, frameColorHover, roundness } = props;
    return (
      <Link
        onClick={onClick}
        to={to}
        css={{
          backgroundColor: frameColor,
          "&:hover": {
            backgroundColor: frameColorHover,
          },
        }}
        className="inline-block"
        style={{
          width: _buttonWidth,
          height: _buttonSize[size],
          borderRadius: _buttonShape[roundness],
          paddingLeft: _buttonSidePadding[size],
          paddingRight: _buttonSidePadding[size],
        }}>
        {children}
      </Link>
    );
  };

  const HrefLink = props => {
    const { children, href, size, frameColor, frameColorHover, roundness } = props;
    return (
      <a
        href={href}
        css={{
          backgroundColor: frameColor,
          "&:hover": {
            backgroundColor: frameColorHover,
          },
        }}
        className="inline-block"
        style={{
          width: _buttonWidth,
          height: _buttonSize[size],
          borderRadius: _buttonShape[roundness],
          paddingLeft: _buttonSidePadding[size],
          paddingRight: _buttonSidePadding[size],
        }}
        target="_blank"
        rel="noopener norefferer">
        {children}
      </a>
    );
  };

  const Button = props => {
    const { children, onClick, size, frameColor, frameColorHover, roundness } = props;
    return (
      <button
        onClick={onClick}
        css={{
          backgroundColor: frameColor,
          "&:hover": {
            backgroundColor: frameColorHover,
          },
        }}
        style={{
          width: _buttonWidth,
          height: _buttonSize[size],
          borderRadius: _buttonShape[roundness],
          paddingLeft: _buttonSidePadding[size],
          paddingRight: _buttonSidePadding[size],
        }}
        className="inline-block outline-none">
        {children}
      </button>
    );
  };

  const Contents = props => {
    const { labelColor } = props;
    return (
      <div className="flex justify-center items-center h-full">
        {_icon()}
        <div
          style={{
            fontSize: _labelSize[size],
            color: labelColor,
            letterSpacing: "-0.01em",
            textAlign: "center",
            verticalAlign: "middle",
          }}>
          {label}
        </div>
      </div>
    );
  };

  const _component =
    mode === "route" ? (
      <GatsbyLink {...props}>
        <Contents {...props} />
      </GatsbyLink>
    ) : mode === "href" ? (
      <HrefLink {...props}>
        <Contents {...props} />
      </HrefLink>
    ) : mode === "button" ? (
      <Button {...props}>
        <Contents {...props} />
      </Button>
    ) : (
      <GatsbyLink {...props}>
        <Contents {...props} />
      </GatsbyLink>
    );

  return <>{_component}</>;
};

Button.propTypes = {
  mode: PropTypes.oneOf(["route", "href", "button"]),
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.element,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  roundness: PropTypes.oneOf(["none", "classic", "round"]),
  labelColor: PropTypes.string,
  frameColor: PropTypes.string,
  frameColorHover: PropTypes.string,
  isStretch: PropTypes.bool,
};
Button.defaultProps = {
  mode: "route",
  label: "Button",
  size: "md",
  iconPosition: "right",
  roundness: "classic",
  labelColor: "white",
  frameColor: "black",
  frameColorHover: "black",
  isStretch: true,
};

export default Button;
