import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, LocaleLink } from "../core";
import { logoAddOnnDark } from "../../assets/graphics";

/**
 * Footer
 */
const Footer = props => {
  return (
    <footer className="bg-altBg1">
      <Wrapper>
        <div className="flex flex-col md:flex-row">
          {company()}
          {menuSection1()}
          {menuSection2()}
          {menuSection3()}
          {menuSection4()}
        </div>

        {copyright()}
      </Wrapper>
    </footer>
  );

  // Company --------------------
  function company() {
    let logo = logoAddOnnDark;

    return (
      <div className="w-auto md:w-3/12 py-20 md:py-40">
        <div className="flex flex-col justify-start items-start">
          <LocaleLink to="/">
            <img
              className="h-40 md:h-44 lg:h-48"
              src={logo}
              alt={props.siteMetaData?.company?.companyName}
            />
          </LocaleLink>
        </div>
      </div>
    );
  }

  // Menu sections --------------------
  function menuSection(sectionTitle = "", menuItems = []) {
    let linkStyle = cx("text-md4 text-text3 tracking-tight_xs h-40", "hover:text-altText2");

    return (
      <div className="flex flex-col">
        <h3 className="font-medium text-sm text-accent2 uppercase tracking-wide_lg h-40">
          {sectionTitle}
        </h3>

        <nav className="flex flex-col">
          {menuItems.map(menuItem => {
            return (
              <LocaleLink
                key={menuItem.slug.current}
                className={linkStyle}
                to={menuItem.slug.current}>
                {menuItem.title.localized}
              </LocaleLink>
            );
          })}
        </nav>
      </div>
    );
  }

  function menuSection1() {
    return (
      <div className="w-auto md:w-2/12 py-20 md:py-40">
        {menuSection(
          props.alternateNav?.menuSections[0].sectionTitle.localized,
          props.alternateNav?.menuSections[0].menuItems ?? []
        )}
      </div>
    );
  }

  function menuSection2() {
    return (
      <div className="w-auto md:w-2/12 py-20 md:py-40">
        {menuSection(
          props.alternateNav?.menuSections[1].sectionTitle.localized,
          props.alternateNav?.menuSections[1].menuItems ?? []
        )}
      </div>
    );
  }

  function menuSection3() {
    return (
      <div className="w-auto md:w-2/12 py-20 md:py-40">
        {menuSection(
          props.alternateNav?.menuSections[2].sectionTitle.localized,
          props.alternateNav?.menuSections[2].menuItems ?? []
        )}
      </div>
    );
  }

  function menuSection4() {
    return (
      <div className="w-auto md:w-2/12 py-20 md:py-40">
        {menuSection(
          props.alternateNav?.menuSections[3].sectionTitle.localized,
          props.alternateNav?.menuSections[3].menuItems ?? []
        )}
      </div>
    );
  }

  // Copyright --------------------
  function copyrightPeriod() {
    let yearNow = new Date().getFullYear();
    let yearGiven = props.siteMetaData.company.startYear ?? yearNow;
    return yearNow === yearGiven ? `${yearGiven}` : `${yearGiven} - ${yearNow}`;
  }

  function copyright() {
    return (
      <div className="wrapper flex pb-40">
        <p className="text-text3 text-sm">
          &copy; {props.siteMetaData?.company?.companyName} {copyrightPeriod()}
        </p>
      </div>
    );
  }
};

import { mockSiteMetaData } from "../../constants/mockData";

Footer.propTypes = {
  siteMetaData: PropTypes.object,
  alternateNav: PropTypes.object,
};
Footer.defaultProps = {
  siteMetaData: mockSiteMetaData,
};

export default Footer;
