import logoAddOnnLight from "./logos/logo-addonn-light.svg";
import logoAddOnnDark from "./logos/logo-addonn-dark.svg";
import dotsBlocksTopLeft from "./patterns/dots-blocks-top-left.svg";
import dotsBlocksBottomRight from "./patterns/dots-blocks-bottom-right.svg";
import tileGeometricCornerTopLeft from "./patterns/tile-geometric-corner-tl.svg";
import tileGeometricCornerTopRight from "./patterns/tile-geometric-corner-tr.svg";
import tileGeometricCornerBottomLeft from "./patterns/tile-geometric-corner-bl.svg";
import tileGeometricCornerBottomRight from "./patterns/tile-geometric-corner-br.svg";
import illustrationBlankBlogPreview from "./illustrations/illustration-blank-blog-preview.svg";

export {
  logoAddOnnLight,
  logoAddOnnDark,
  dotsBlocksTopLeft,
  dotsBlocksBottomRight,
  tileGeometricCornerTopLeft,
  tileGeometricCornerTopRight,
  tileGeometricCornerBottomLeft,
  tileGeometricCornerBottomRight,
  illustrationBlankBlogPreview,
};
