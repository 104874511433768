import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

/**
 * IconFrame
 */
const IconFrame = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: props.framePadding,
        backgroundColor: props.frameColor,
        borderRadius: 9999,
      }}>
      <div style={{ width: props.iconSize, height: props.iconSize }}>
        <img style={{ width: "100%", height: "100%" }} src={props.iconUrl} />
      </div>
    </div>
  );
};

IconFrame.propTypes = {
  iconUrl: PropTypes.string,
  iconSize: PropTypes.number,
  framePadding: PropTypes.number,
};
IconFrame.defaultProps = {
  iconSize: 32,
  framePadding: 16,
  frameColor: theme.colors.accent2_dimmed,
};

export default IconFrame;
