import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

/**
 * Wrapper
 */
const Wrapper = props => {
  return (
    <div
      className={cx(
        "wrapper",
        props.isFullHeight ? "h-full" : "h-auto",
        props.hasSidePadding ? "px-20 md:px-30 lg:px-30" : null,
        props.showDebugBg ? "bg-red-200" : null
      )}>
      {props.children}
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isFullHeight: PropTypes.bool,
  hasSidePadding: PropTypes.bool,
  showDebugBg: PropTypes.bool,
};
Wrapper.defaultProps = { isFullHeight: false, hasSidePadding: true, showDebugBg: false };

export default Wrapper;
