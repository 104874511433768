import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Button } from "../core";

import LocaleContext from "../../providers/LocaleContext";

const localizedPath = require("../../helpers/localeHelpers").localizedPath;

/**
 * LocaleButton
 */
const LocaleButton = props => {
  const localeContext = useContext(LocaleContext);

  function onClick() {
    localeContext.changePath(props.to);
  }

  return (
    <Button
      onClick={onClick}
      to={localizedPath(localeContext.locale, props.to)}
      href={props.href}
      mode={props.mode}
      label={props.label}
      icon={props.icon}
      iconPosition={props.iconPosition}
      size={props.size}
      roundness={props.roundness}
      labelColor={props.labelColor}
      frameColor={props.frameColor}
      frameColorHover={props.frameColorHover}
      isStretch={props.isStretch}
    />
  );
};

LocaleButton.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  mode: PropTypes.oneOf(["route", "href", "button"]),
  label: PropTypes.string,
  icon: PropTypes.element,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  roundness: PropTypes.oneOf(["none", "classic", "round"]),
  labelColor: PropTypes.string,
  frameColor: PropTypes.string,
  frameColorHover: PropTypes.string,
  isStretch: PropTypes.bool,
};
LocaleButton.defaultProps = {
  mode: "route",
  label: "Button",
  size: "md",
  iconPosition: "right",
  roundness: "classic",
  labelColor: "white",
  frameColor: "black",
  frameColorHover: "black",
  isStretch: true,
};

export default LocaleButton;
