import React from "react";
import PropTypes from "prop-types";
import BlockContent from "@sanity/block-content-to-react";
import cx from "classnames";

import { Wrapper } from "../../components/core";

/**
 * RichTextContent
 */
const RichTextContent = props => {
  const heading2 = children => (
    <h2 className="text-md3 md:text-md2 text-accent1 py-24">{children}</h2>
  );

  const heading3 = children => (
    <h3 className="text-md4 md:text-md3 text-text1 py-18">{children}</h3>
  );

  const body = children => (
    <p className="text-sm md:text-md4 text-text2 leading-loose py-8">{children}</p>
  );

  const list = children => <ul className="list-disc pl-18 py-8">{children}</ul>;

  const listItem = children => (
    <li className="text-sm md:text-md4 text-text2 leading-relaxed py-4">{children}</li>
  );

  const link = (href, children) => (
    <a className="text-accent1" href={href} target="_blank">
      {children}
    </a>
  );

  const serializers = {
    types: {
      block: props => {
        switch (props.node.style) {
          case "h2":
            return heading2(props.children);
          case "h3":
            return heading3(props.children);
          case "normal":
            return body(props.children);
          default:
            return body(props.children);
        }
      },
    },
    list: props => {
      return list(props.children);
    },
    listItem: props => {
      return listItem(props.children);
    },
    marks: {
      link: props => {
        return link(props.mark.href, props.children);
      },
    },
  };

  const imageOptions = {};

  return (
    <BlockContent
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.SANITY_DATASET}
      blocks={props.blocks}
      serializers={serializers}
      imageOptions={imageOptions}
    />
  );
};

RichTextContent.propTypes = {
  blocks: PropTypes.array,
};
RichTextContent.defaultProps = {
  blocks: [],
};

export default RichTextContent;
