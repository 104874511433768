import React from "react";
import PropTypes from "prop-types";

const IconMinus = ({ color, size, strokeWidth }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M5 12h14" />
    </svg>
  );
};

IconMinus.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};
IconMinus.defaultProps = { color: "black", size: 24, strokeWidth: 2 };

export default IconMinus;
