import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

/**
 * TabButton
 */
const TabButton = props => {
  const contents = () => {
    return (
      <div
        css={{
          "&:hover > .tabButton": {
            color: props.labelColorHover,
          },
        }}
        className="flex items-center h-full">
        <div className="bg-bg1 rounded border border-gray-300">
          <img className="w-48 rounded" src={props.iconUrl} alt={props.label} />
        </div>

        <div
          css={{
            color: props.labelColor,
          }}
          style={{
            fontSize: 18,
            letterSpacing: "-0.01em",
            textAlign: props.isTextAlignLeft ? "left" : "center",
            verticalAlign: "middle",
            paddingLeft: props.isTextAlignLeft ? 10 : 0,
          }}
          className="tabButton w-full">
          {props.label}
        </div>
      </div>
    );
  };

  return (
    <button
      onClick={props.onClick}
      style={{
        width: props.isStretch ? "100%" : "auto",
        height: 56,
        backgroundColor: props.frameColor,
        borderRadius: 12,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 16,
      }}
      className="inline-block outline-none">
      {contents()}
    </button>
  );
};

TabButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  iconUrl: PropTypes.string,
  labelColor: PropTypes.string,
  labelColorHover: PropTypes.string,
  frameColor: PropTypes.string,
  isTextAlignLeft: PropTypes.bool,
  isStretch: PropTypes.bool,
};
TabButton.defaultProps = {
  label: "Tab",
  labelColor: "white",
  frameColor: "black",
  frameColorHover: "black",
  isTextAlignLeft: false,
  isStretch: true,
};

/**
 * SolutionsTabNav
 */
const SolutionsTabNav = props => {
  let tabDirection = {
    row: "flex-col md:flex-row",
    col: "flex-col",
  };

  let textAlign = {
    row: false,
    col: true,
  };

  return (
    <div
      className={cx(
        "flex",
        tabDirection[props.tabDirection],
        "w-full",
        "rounded bg-accent1_dimmed"
      )}>
      {props.tabItems.map((item, index) => {
        return index === props.activeTab ? (
          <TabButton
            key={item.title}
            onClick={() => props.onClick(index)}
            label={item.title}
            iconUrl={item.iconUrl}
            labelColor={theme.colors.altText1}
            labelColorHover={theme.colors.altText1}
            frameColor={theme.colors.accent1}
            isTextAlignLeft={textAlign[props.tabDirection]}
          />
        ) : (
          <TabButton
            key={item.title}
            onClick={() => props.onClick(index)}
            label={item.title}
            iconUrl={item.iconUrl}
            labelColor={theme.colors.text1}
            labelColorHover={theme.colors.accent1}
            frameColor={theme.colors.accent1_dimmed}
            isTextAlignLeft={textAlign[props.tabDirection]}
          />
        );
      })}
    </div>
  );
};

SolutionsTabNav.propTypes = {
  onClick: PropTypes.func,
  activeTab: PropTypes.number.isRequired,
  tabItems: PropTypes.array.isRequired,
  tabDirection: PropTypes.oneOf(["row", "col"]),
  isTextAlignLeft: PropTypes.bool,
};
SolutionsTabNav.defaultProps = {
  activeTab: 0,
  tabItems: [],
  tabDirection: "row",
  textAlign: "center",
  isTextAlignLeft: false,
};

export default SolutionsTabNav;
