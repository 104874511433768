import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";

import LocaleContext from "../../providers/LocaleContext";

const localizedPath = require("../../helpers/localeHelpers").localizedPath;

/**
 * LocaleLink
 */
const LocaleLink = props => {
  const localeContext = useContext(LocaleContext);

  function onClick() {
    localeContext.changePath(props.to);
  }

  return (
    <Link
      onClick={onClick}
      to={localizedPath(localeContext.locale, props.to)}
      className={props.className}
      activeStyle={props.activeStyle}>
      {props.children}
    </Link>
  );
};

LocaleLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  activeStyle: PropTypes.object,
};
LocaleLink.defaultProps = {};

export default LocaleLink;
