module.exports = {
  mq: breakpoint => {
    return `@media (min-width: ${breakpoint})`;
  },
  screens: {
    md: `768px`,
    lg: `1024px`,
    xl: `1600px`,
  },
  colors: {
    none: "transparent",

    accent1: `var(--color_accent1)`,
    accent1_hover: `var(--color_accent1_hover)`,
    accent1_dimmed: `var(--color_accent1_dimmed)`,
    accent1_dimmed_hover: `var(--color_accent2_dimmed_hover)`,
    accent1_20: `var(--color_accent1_20)`,
    accent2: `var(--color_accent2)`,
    accent2_hover: `var(--color_accent2_hover)`,
    accent2_dimmed: `var(--color_accent2_dimmed)`,
    accent2_dimmed_hover: `var(--color_accent2_dimmed_hover)`,
    accent2_20: `var(--color_accent2_20)`,

    text1: `var(--color_text1)`,
    text2: `var(--color_text2)`,
    text3: `var(--color_text3)`,
    altText1: `var(--color_altText1)`,
    altText1_60: `var(--color_altText1_60)`,
    altText2: `var(--color_altText2)`,
    altText3: `var(--color_altText3)`,

    bg1: `var(--color_bg1)`,
    bg1_hover: `var(--color_bg1_hover)`,
    bg2: `var(--color_bg2)`,
    colorBg1: `var(--color_colorBg1)`,
    colorBg2: `var(--color_colorBg2)`,
    altBg1: `var(--color_altBg1)`,
    altBg1_dimmed: `var(--color_altBg1_dimmed)`,
    altBg1_dimmed_hover: `var(--color_altBg1_dimmed_hover)`,
    altBg2: `var(--color_altBg2)`,

    statusPositive: `var(--color_statusPositive)`,
    statusWarning: `var(--color_statusWarning)`,
    statusFailure: `var(--color_statusFailure)`,
  },
  colorsTw: {
    bg_bg1: "bg-bg1",
    bg_bg2: "bg-bg2",
    bg_altBg1: "bg-altBg1",
    bg_accent1: "bg-accent1",
    bg_accent1_dimmed: "bg-accent1_dimmed",
    bg_accent2: "bg-accent2",
    bg_accent2_dimmed: "bg-accent2_dimmed",
  },
  fontSize: {
    lg: `var(--fontSize_lg)`,
    lg2: `var(--fontSize_lg2)`,
    lg3: `var(--fontSize_lg3)`,
    lg4: `var(--fontSize_lg4)`,
    md: `var(--fontSize_md)`,
    md2: `var(--fontSize_md2)`,
    md3: `var(--fontSize_md3)`,
    md4: `var(--fontSize_md4)`,
    sm: `var(--fontSize_sm)`,
    sm2: `var(--fontSize_sm2)`,
    sm3: `var(--fontSize_sm3)`,
    sm4: `var(--fontSize_sm4)`,
  },
  fontSizeKerned: {
    lg: [`var(--fontSize_lg)`, { letterSpacing: `-0.03em` }],
    lg2: [`var(--fontSize_lg2)`, { letterSpacing: `-0.03em` }],
    lg3: [`var(--fontSize_lg3)`, { letterSpacing: `-0.03em` }],
    lg4: [`var(--fontSize_lg4)`, { letterSpacing: `-0.03em` }],
    md: [`var(--fontSize_md)`, { letterSpacing: `-0.01em` }],
    md2: [`var(--fontSize_md2)`, { letterSpacing: `-0.01em` }],
    md3: [`var(--fontSize_md3)`, { letterSpacing: `-0.01em` }],
    md4: [`var(--fontSize_md4)`, { letterSpacing: `-0.01em` }],
    sm: [`var(--fontSize_sm)`, { letterSpacing: `0em` }],
    sm2: [`var(--fontSize_sm2)`, { letterSpacing: `0em` }],
    sm3: [`var(--fontSize_sm3)`, { letterSpacing: `0em` }],
    sm4: [`var(--fontSize_sm4)`, { letterSpacing: `0em` }],
  },
  spacing: {
    sectionY: "py-40 md:py-60 lg:py-80",
    sectionY_lg: "py-60 md:py-80 lg:py-100",
    sectionY_md: "py-40 md:py-60",
    sectionYTight: "py-20 md:py-40",
    sectionYTop: "pt-40 md:pt-60 lg:pt-80",
    sectionYTopExtra: "pt-120 md:pt-160",
    sectionYBottom: "pb-40 md:pb-60 lg:pb-80",
    blockY: "h-40 md:h-60",
    blockYTight: "h-40",
    blockYLoose: "h-40 md:h-60 lg:h-80",
    cardPadding: "px-30 py-40 md:py-60",
    spacerH_titleContent: "h-30 md:h-40 lg:h-60 bg-transparent",
  },
};
