import React, { useContext } from "react";
import PropTypes from "prop-types";
import BlockContent from "@sanity/block-content-to-react";
import cx from "classnames";

import LocaleContext from "../../providers/LocaleContext";

const localizedPath = require("../../helpers/localeHelpers").localizedPath;
const defaultLocale = require("../../constants/defaultLocale");

/**
 * RichDescription
 */
const RichDescription = props => {
  const localeContext = useContext(LocaleContext);

  const keyLocale = `_raw${
    localeContext.locale.charAt(0).toUpperCase() + localeContext.locale.slice(1)
  }`;
  const keyDefault = `_raw${defaultLocale.charAt(0).toUpperCase() + defaultLocale.slice(1)}`;
  const blocks = props.blocksObject[keyLocale] || props.blocksObject[keyDefault];

  const body = children => {
    let type =
      props.type === "body"
        ? "text-sm md:text-md4"
        : props.type === "sectionDescription"
        ? "text-md4 md:text-md3"
        : "text-sm md:text-md4";

    return (
      <p className={cx(type, props.color, "leading-loose py-12", props.className)}>{children}</p>
    );
  };

  const list = children => (
    <ul className={cx("list-disc pl-18 py-8", props.className)}>{children}</ul>
  );

  const listItem = children => {
    return (
      <li
        className={cx("text-sm md:text-md4", props.color, "leading-relaxed py-4", props.className)}>
        {children}
      </li>
    );
  };

  const link = (href, children) => {
    let color = props.theme === "light" ? "text-accent1" : "text-altText1";
    return (
      <a className={cx(color, props.className)} href={href} target="_blank">
        {children}
      </a>
    );
  };

  const strong = children => <span className={cx("font-medium", props.className)}>{children}</span>;

  const em = children => <span className={cx("italic", props.className)}>{children}</span>;

  const serializers = {
    types: {
      block: props => {
        switch (props.node.style) {
          case "normal":
            return body(props.children);
          default:
            return body(props.children);
        }
      },
    },
    list: props => {
      return list(props.children);
    },
    listItem: props => {
      return listItem(props.children);
    },
    marks: {
      link: props => {
        return link(props.mark.href, props.children);
      },
      strong: props => {
        return strong(props.children);
      },
      em: props => {
        return em(props.children);
      },
    },
  };

  const imageOptions = {};

  return (
    <BlockContent
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.SANITY_DATASET}
      blocks={blocks}
      serializers={serializers}
      imageOptions={imageOptions}
    />
  );
};

RichDescription.propTypes = {
  blocksObject: PropTypes.object,
  theme: PropTypes.oneOf(["light", "dark"]),
  color: PropTypes.string,
  type: PropTypes.oneOf(["body", "sectionDescription"]),
  className: PropTypes.string,
};
RichDescription.defaultProps = {
  theme: "light",
  color: "text-text2",
  type: "body",
};

export default RichDescription;
