import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import "./styles/reset.css";
import "./styles/global.css";
import "./styles/generated/tailwind.css";

import LocaleContext from "../providers/LocaleContext";

import { NavBar, MobileNav, Footer, Cookie } from "./core";

/**
 * Layout
 */
const Layout = props => {
  const localeContext = useContext(LocaleContext);

  useEffect(() => {
    localeContext.changeLocale(props.locale);
  }, [props.locale]);

  return (
    <div className="relative bg-bg1">
      {/* <Cookie /> */}
      <MobileNav siteMetaData={props.siteMetaData} alternateNav={props.alternateNav} />
      <NavBar siteMetaData={props.siteMetaData} mainNav={props.mainNav} />
      <main>{props.children}</main>
      <Footer siteMetaData={props.siteMetaData} alternateNav={props.alternateNav} />
    </div>
  );
};

Layout.propTypes = {
  locale: PropTypes.string,
  siteMetaData: PropTypes.object,
  mainNav: PropTypes.object,
  alternateNav: PropTypes.object,
  children: PropTypes.node.isRequired,
  navBarTheme: PropTypes.oneOf(["light", "dark"]),
};
Layout.defaultProps = {
  navBarTheme: "light",
};

export default Layout;
